<template>
  <div class="header">
    <slot>
      <img src="../../assets/images/logo-nph.png" class="logo" alt="Logo">
    </slot>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped lang="scss">
.header {
  background: linear-gradient(270deg, rgb(255, 117, 58) 0px, rgb(251, 95, 20) 100%);
  text-align: center;
  padding: 40px 15px 100px 15px;
  margin-bottom: -60px;
  
  img {
    width: 300px;
    max-width: 100%;
  }
}
</style>