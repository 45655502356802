<template>
  <div class="dialog-card">
    <div class="card-heading">
      <h1 v-if="title" class="card-title">{{title}}</h1>
      <div v-if="description" class="card-description text-fade" v-html="description"></div>
    </div>
    <slot>
      
    </slot>
  </div>
</template>

<script>
export default {
  name: "DialogCard",
  props: ["title", "description"]
}
</script>

<style scoped lang="scss">
.dialog-card {
  display: block;
  background: #ffffff;
  box-shadow: 0 0 13px rgba(0, 0, 0, .2);
  border-radius: 6px;
  padding: 30px 15px;
  margin: 0 auto 15px auto;
  max-width: calc(100% - 30px);
  width: 500px;
  
  .card-heading {
    margin: 0 0 30px 0;

    .card-title {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
    }
  }
}
</style>