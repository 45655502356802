import httpHelper from "@/helpers/httpHelper";

const authenticateService = {
    async login(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/Authenticate/Login", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
    async register(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/Authenticate/Register", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
    async recoveryPassword(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/Authenticate/RecoveryPassword", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
    async changePassword(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/Authenticate/ChangePassword", data, {
            headers: {
                reCaptchaToken
            }
        });
    }
};

export default authenticateService;