<template>
  <div class="page page-recovery-password">
    <Header/>
    <DialogCard :title="$t('Recovery a password')" :description="$t('Enter your username, email or phone number to get code')">
      <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label class="form-control-label">{{ $t("Username/Email/Mobile") }}<i class="required"></i></label>
            <b-form-input v-model="formData.username" :placeholder="$t('Enter an username/email/mobile')" :readonly="loading" autocomplete="off"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group class="mb-0">
          <b-button type="submit" variant="primary" block size="lg" class="font-weight-bold text-uppercase" :disabled="loading">
            {{ $t("Next") }}
          </b-button>
        </b-form-group>
      </ValidationObserver>
    </DialogCard>
    <LoadingModal :show="loading"/>
  </div>
</template>

<script>
import Header from "@/components/headers/Header";
import DialogCard from "@/components/cards/DialogCard";
import dialogHelper from "@/helpers/dialogHelper";
import authenticateService from "@/services/authenticateService";

export default {
  name: "RecoveryPassword",
  components: {Header, DialogCard},
  data() {
    return {
      loading: false,
      showPass: false,
      formData: {
        username: ""
      }
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await authenticateService.recoveryPassword(this.formData, await this.$recaptcha('recoveryPassword'))
      this.loading = false;

      if (!response) {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      await this.$store.dispatch("setRecoveryPassword", response.data);
      await this.$router.push({name: "ChangePasswordByCode"})
    }
  }
}
</script>